import { useEffect, useRef } from 'react';
import { mapCoupons, mapProductForCheckoutEvents } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '@vaimo/google-tag-manager/src/context/gtm';
import { usePageType } from './usePageType';

export const useBeginCheckout = ({ checkoutStep, isSignedIn, orderDetailsData, ...restData }) => {
    const [{ isEnabled }] = useGtmContext();
    const condition = usePageType({ ...restData });

    //TODO: rework this after refactoring of GTM, HL-6499
    const lengthRefBefore = useRef(0);
    const lengthRefAfter = useRef(0);

    useEffect(() => {
        if (
            condition ||
            !isEnabled ||
            !orderDetailsData?.cartItems?.length ||
            !orderDetailsData.cartItems.every((item) => item.prices) ||
            checkoutStep > 1
        ) {
            return;
        }

        if (!isSignedIn && !lengthRefBefore.current) {
            lengthRefBefore.current = orderDetailsData?.cartItems?.length;
        }

        if (isSignedIn && !lengthRefAfter.current) {
            lengthRefAfter.current = orderDetailsData?.cartItems?.length;
        }

        if (isSignedIn && lengthRefBefore.current) {
            return;
        }

        if (
            lengthRefBefore.current &&
            lengthRefAfter.current &&
            lengthRefBefore.current + lengthRefAfter.current !== orderDetailsData?.cartItems?.length
        ) {
            return;
        }

        actionsTagManager.resetEcommerce();
        actionsTagManager.beginCheckout({
            coupon: mapCoupons(orderDetailsData),
            items: orderDetailsData.cartItems.map(mapProductForCheckoutEvents),
        });
    }, [
        condition,
        checkoutStep > 1,
        isEnabled,
        orderDetailsData?.cartItems?.length,
        orderDetailsData?.appliedCoupons?.length,
    ]);
};
